import { Component, OnInit } from '@angular/core';
import { CoursesService } from '../../services/courses.service';
import { BrowseCourseViewModel, CourseDto, CourseStatus } from '../../services/model';
import { TranslateService } from '@ngx-translate/core';
import { LogsService } from '../../services/logs.service';
import { PushSubscriptionService } from '../../services/push.subscription.service';

@Component({
  selector: 'app-courses-list',
  templateUrl: './courses-list.component.html',
  styleUrls: ['./courses-list.component.scss']
})
export class CoursesListComponent implements OnInit {

  loadCard: boolean;
  allCourses: BrowseCourseViewModel[];

  constructor(
    private coursesService: CoursesService,
    private translateService: TranslateService,
    private logs: LogsService,
    private pushService: PushSubscriptionService
  ) { }

  ngOnInit() {
    this.pushService.AskUserForPushSubscriptionIfNeeded();
    var pageSize = 20;
    this.loadCard = true;
    this.coursesService.getCoursesFromApiSmart(pageSize).subscribe(res => {
      // this.logs.Log(res);
      this.allCourses = res.map<BrowseCourseViewModel>(this.mapDtoToViewModel.bind(this));
      this.loadCard = false;
    }, error => {
      this.loadCard = false;
    });
  }

  private mapDtoToViewModel(d: CourseDto): BrowseCourseViewModel {
    return <BrowseCourseViewModel>{
      courseId: d.id,
      arrivalCity: d.arrivalCity,
      arrivalCountry: d.arrivalCountry,
      cargoType: this.mapCargoType(d.cargoType),
      truckType: this.mapTruckType(d.truckType),
      departureCity: d.departureCity,
      departureCountry: d.departureCountry,
      distance: d.calculatedDistance ? d.calculatedDistance : '-- km',
      driverDocuments: this.mapDriverDocuments(d.driverDocuments),
      price: d.priceValue + ' ' + d.priceCurrency,
      startDate: this.convertServerTimeToLocal(d.startDate),
      phoneNumber: d.phoneNumber,
      status: this.mapStatus(d.status),
      statusText: this.mapStatusText(d.status),
      isEditable: d.isEditable,
      actions: {
        canEdit: d.actions.canEdit,
        canRegister: d.actions.canRegister,
        canUnregister: d.actions.canUnregister,
        canDelete: d.actions.canDelete,
        canViewDetails: d.actions.canViewDetails
      }
    }
  }

  private convertServerTimeToLocal(datetime: string): string {
    return datetime.substr(0, 10);
  }

  private mapDriverDocuments(fromServer: string[]): string[] {
    return fromServer.map(x => this.translateService.instant('dict.' + x));
  }

  private mapCargoType(fromServer: string): string {
    return this.translateService.instant('dict.' + fromServer);
  }

  private mapTruckType(fromServer: string): string {
    return this.translateService.instant('dict.' + fromServer);
  }

  private mapStatusText(fromServer: string): string {
    return this.translateService.instant('dict.' + fromServer);
  }

  private mapStatus(fromServer: string): CourseStatus {
    if (fromServer == 'DS_NEW') return CourseStatus.New;
    if (fromServer == 'DS_REGISTERED') return CourseStatus.Registered;
    if (fromServer == 'DS_ONGOING') return CourseStatus.Ongoing;
    if (fromServer == 'DS_EXPIRED') return CourseStatus.Expired;
    if (fromServer == 'DS_EXECUTED') return CourseStatus.Executed;
    if (fromServer == 'DS_FINISHED') return CourseStatus.Finished;
    this.logs.Error('Unknown course status: [' + fromServer + ']');
    return CourseStatus.Unknown;
  }

  public isNew(status: CourseStatus): boolean {
    return status == CourseStatus.New;
  }

  public canUnregister(status: CourseStatus, isEditable: boolean): boolean {
    return this.isRegistered(status) && isEditable;
  }

  public isRegistered(status: CourseStatus): boolean {
    return status == CourseStatus.Registered;
  }

  public call(phoneNumber: string) {
    // window.open('tel:' + phoneNumber);
    document.getElementById('call_' + phoneNumber).click();
  }

  public delete(courseId: string) {
    if (courseId) {
      var courses = this.allCourses.filter(x => x.courseId == courseId);
      if (courses.length == 1) {
        var c = courses[0];
        var text = this.translateService.instant(
          'courses.browse.text_action_delete_confirm_template',
          {
            courseDeparture: c.departureCity,
            courseArrival: c.arrivalCity
          })
        if (confirm(text)) {
          this.coursesService.deleteCourse(c.courseId).subscribe(result => {
            if (result.code === 200) {
              this.showNotificationWithTranslation('courses.browse.text_course_deleted');
              this.allCourses = this.allCourses.filter(x => x.courseId != c.courseId);
            } else {
              LogsService.Error(result);
              this.showNotificationWithTranslation('courses.browse.text_delete_error');
            }
          }, error => {
            LogsService.Error(error);
            this.showNotificationWithTranslation('courses.browse.text_delete_error');
          });
        }
      }
    }
  }

  showNotification(message: string) {
    alert(message);
    LogsService.Log(message);
  }

  showNotificationWithTranslation(message: string) {
    this.showNotification(this.translateService.instant(message));
  }

  courseClass(course: CourseDto): string {
    if(
      course.status == CourseStatus.Finished.toString() ||
      course.status == CourseStatus.Executed.toString() ||
      course.status == CourseStatus.Expired.toString() 
    ) {
      return 'course-finished';
    }
    if(course.status == CourseStatus.Registered.toString()) {
      return 'bg-success';
    }
    return '';
  }

  anyCourses(): boolean {
    return this.allCourses && this.allCourses.length > 0;
  }

  shouldShowFooter(course: BrowseCourseViewModel): boolean {
    return course.actions.canViewDetails || course.actions.canDelete;
  }

  canViewDetails(course: BrowseCourseViewModel): boolean {
    return course.actions.canViewDetails;
  }

  canDelete(course: BrowseCourseViewModel): boolean {
    return course.actions.canDelete;
  }
}
