<div class="page-body">
  <div class="row">
    <div class="col-sm-12">
      <form class="form-material" [formGroup]="courseForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-md-6">
            <!-- Miejsce startowe -->
            <div class="card" formGroupName="departure">
              <div class="card-header">
                <h5>{{'courses.cud.cards.departure.title' | translate}}</h5>
              </div>
              <div class="row card-block">
                <div class="col-sm-6">
                  <div class="form-group form-static-label" [ngClass]="getClassForRequiredFormGroupField('departure', 'country')">
                    <input type="text" class="form-control" id="iDepCo" placeholder="{{'courses.cud.cards.departure.country' | translate}}" formControlName="country"
                      required>
                    <span class="form-bar"></span>
                    <label class="float-label" for="iDepCo">{{'courses.cud.cards.departure.country' | translate}}</label>
                    <div class="messages text-danger" *ngIf="isRequiredGroupFieldMissing('departure', 'country')">{{'courses.cud.form_validation.field_required' | translate}}</div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group form-static-label" [ngClass]="getClassForRequiredFormGroupField('departure', 'city')">
                    <input type="email" class="form-control" id="iDepCity" placeholder="{{'courses.cud.cards.departure.city' | translate}}" formControlName="city">
                    <span class="form-bar"></span>
                    <label class="float-label" for="iDepCity">{{'courses.cud.cards.departure.city' | translate}}</label>
                    <div class="messages text-danger" *ngIf="isRequiredGroupFieldMissing('departure', 'city')">{{'courses.cud.form_validation.field_required' | translate}}</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Miejsce docelowe -->
            <div class="card" formGroupName="arrival">
              <div class="card-header">
                <h5>{{'courses.cud.cards.arrival.title' | translate}}</h5>
              </div>
              <div class="row card-block">
                <div class="col-sm-6">
                  <div class="form-group form-static-label" [ngClass]="getClassForRequiredFormGroupField('arrival', 'country')">
                    <input type="text" class="form-control" id="iArrCo" placeholder="{{'courses.cud.cards.arrival.country' | translate}}" formControlName="country">
                    <span class="form-bar"></span>
                    <label class="float-label" for="iArrCo">{{'courses.cud.cards.arrival.country' | translate}}</label>
                    <div class="messages text-danger" *ngIf="isRequiredGroupFieldMissing('arrival', 'country')">{{'courses.cud.form_validation.field_required' | translate}}</div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group form-static-label" [ngClass]="getClassForRequiredFormGroupField('arrival', 'city')">
                    <input type="email" class="form-control" id="iArrCity" placeholder="{{'courses.cud.cards.arrival.city' | translate}}" formControlName="city">
                    <span class="form-bar"></span>
                    <label class="float-label" for="iArrCity">{{'courses.cud.cards.arrival.city' | translate}}</label>
                    <div class="messages text-danger" *ngIf="isRequiredGroupFieldMissing('arrival', 'city')">{{'courses.cud.form_validation.field_required' | translate}}</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Daty -->
            <div class="card">
              <div class="card-header">
                <h5>{{'courses.cud.cards.dates.title' | translate}}</h5>
              </div>
              <div class="row card-block">
                <div class="col-sm-6">
                  <div class="form-group form-static-label" [ngClass]="getClassForRequiredFormField('startDate')">
                    <input type="date" class="form-control" id="iDateS" placeholder="{{'courses.cud.cards.dates.start_date' | translate}}" formControlName="startDate">
                    <span class="form-bar"></span>
                    <label class="float-label" for="iDateS">{{'courses.cud.cards.dates.start_date' | translate}}</label>
                    <div class="messages text-danger" *ngIf="isRequiredFieldMissing('startDate')">{{'courses.cud.form_validation.field_required' | translate}}</div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group form-static-label" [ngClass]="getClassForRequiredFormField('approximateEndDate')">
                    <input type="date" class="form-control" id="iDateE" placeholder="{{'courses.cud.cards.dates.end_date' | translate}}e" formControlName="approximateEndDate">
                    <span class="form-bar"></span>
                    <label class="float-label" for="iDateE">{{'courses.cud.cards.dates.end_date' | translate}}</label>
                    <div class="messages text-danger" *ngIf="isRequiredFieldMissing('approximateEndDate')">{{'courses.cud.form_validation.field_required' | translate}}</div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Cena -->
            <div class="card" formGroupName="price">
              <div class="card-header">
                <h5>{{'courses.cud.cards.price.title' | translate}}</h5>
              </div>
              <div class="row card-block">
                <div class="col-sm-6">
                  <div class="form-group form-static-label" [ngClass]="getClassForRequiredFormGroupField('price', 'value')">
                    <input type="text" class="form-control" id="iPrValue" placeholder="{{'courses.cud.cards.price.price_value' | translate}}"
                      formControlName="value">
                    <span class="form-bar"></span>
                    <label class="float-label" for="iPrValue">{{'courses.cud.cards.price.price_value' | translate}}</label>
                    <div class="messages text-danger" *ngIf="isRequiredGroupFieldMissing('price', 'value')">{{'courses.cud.form_validation.field_required' | translate}}</div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group form-static-label" [ngClass]="getClassForRequiredFormGroupField('price', 'currency')">
                    <input type="text" class="form-control" id="iPrCurr" placeholder="{{'courses.cud.cards.price.price_currency' | translate}}"
                      formControlName="currency">
                    <span class="form-bar"></span>
                    <label class="float-label" for="iPrCurr">{{'courses.cud.cards.price.price_currency' | translate}}</label>
                    <div class="messages text-danger" *ngIf="isRequiredGroupFieldMissing('price', 'currency')">{{'courses.cud.form_validation.field_required' | translate}}</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Kontakt -->
            <div class="card">
              <div class="card-header">
                <h5>{{'courses.cud.cards.contact.title' | translate}}</h5>
              </div>
              <div class="row card-block">
                <div class="col-sm-6">
                  <div class="form-group form-static-label" [ngClass]="getClassForRequiredFormField('phoneNumber')">
                    <input type="text" class="form-control" id="iConPho" placeholder="{{'courses.cud.cards.contact.phone_number' | translate}}"
                      formControlName="phoneNumber">
                    <span class="form-bar"></span>
                    <label class="float-label" for="iConPho">{{'courses.cud.cards.contact.phone_number' | translate}}</label>
                    <div class="messages text-danger" *ngIf="isRequiredFieldMissing('phoneNumber')">{{'courses.cud.form_validation.field_required' | translate}}</div>
                    <!--<div class="messages text-danger" *ngIf="courseForm.controls.phoneNumber.touched && (!courseForm.controls.phoneNumber.errors?.required && courseForm.controls.phoneNumber.errors?.validatePhone)">{{'auth.register.form_validation.email_invalid' | translate}}</div>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <!-- Mapa -->
            <div class="card" formGroupName="arrival">
              <div class="card-header">
                <h5>{{'courses.cud.cards.map.title' | translate}}</h5>
              </div>
              <div class="row card-block">
                <div class="col-sm-12">
                  <agm-map [latitude]="mapLatitude" [longitude]="mapLongitude">
                    <agm-direction (onResponse)="mapsResponse($event)" [visible]="showDirections" [origin]="origin" [destination]="destination"></agm-direction>
                  </agm-map>
                </div>
              </div>
            </div>
            <!-- Trasa  -->
            <div class="card">
              <div class="card-header">
                <h5>{{'courses.cud.cards.route.title' | translate}}</h5>
              </div>
              <div class="row card-block">
                <div class="col-sm-6">
                  <div class="form-group form-static-label">
                    <input id="calculatedDistance" type="text" disabled class="form-control" placeholder="{{'courses.cud.cards.route.distance' | translate}}"
                      value="{{mapRouteDistance}}">
                    <span class="form-bar"></span>
                    <label class="float-label">{{'courses.cud.cards.route.distance' | translate}}</label>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group form-static-label">
                    <input id="calculatedDuration" type="text" disabled class="form-control" placeholder="{{'courses.cud.cards.route.duration' | translate}}"
                      value="{{mapRouteDuration}}">
                    <span class="form-bar"></span>
                    <label class="float-label">{{'courses.cud.cards.route.duration' | translate}}</label>
                  </div>
                </div>
              </div>
            </div>
            <!-- Dodatkowe informacje -->
            <div class="card">
              <div class="card-header">
                <h5>{{'courses.cud.cards.additional.title' | translate}}</h5>
              </div>
              <div class="row card-block">
                <div class="col-sm-4">
                  <div class="form-group form-static-label" [ngClass]="'has-errors'">
                    <label>{{'courses.cud.cards.additional.cargo_type' | translate}}</label>
                    <label class="custom-radio" *ngFor="let ct of dictCargoTypes" style="display: inline !important">
                      <br />
                      <input type="radio" [value]="ct.id" formControlName="cargoType">
                      <span>{{('dict.' + ct.name) | translate}}</span>
                    </label>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group form-static-label">
                    <label>{{'courses.cud.cards.additional.truck_type' | translate}}</label>
                    <label class="custom-radio" *ngFor="let tt of dictTruckTypes" style="display: inline !important">
                      <br/>
                      <input type="radio" [value]="tt.id" formControlName="truckType">
                      <span>{{('dict.' + tt.name) | translate}}</span>
                    </label>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label>{{'courses.cud.cards.additional.driver_documents' | translate}}</label>
                    <label formArrayName="driverDocuments" class="custom-checkbox" *ngFor="let dd of courseForm['controls'].driverDocuments['controls']; let i = index"
                      style="display: inline !important">
                      <br />
                      <input type="checkbox" [formControlName]="i">
                      <span>{{('dict.' + dictDriverDocuments[i].name) | translate}}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="alert alert-info">
          <span>{{'courses.cud.messages.course_status_is' | translate}}</span>&nbsp;
          <span>{{'dict.' + course.status | translate}}</span>
        </div>

        <div *ngIf="shouldDisplayDriverDetails()" class="row">
          <div class="col-md-6">
            <!-- Driver details -->
            <div class="card">
              <div class="card-header">
                <h5>{{'courses.cud.cards.driver.title' | translate}}</h5>
              </div>
              <div class="row card-block">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label>{{'courses.cud.cards.driver.name' | translate}} : {{driverName}}</label>
                    <label>{{'courses.cud.cards.driver.phone' | translate}} : {{driverPhone}}</label>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <button type="button" class="btn btn-danger" (click)="fireDriver()">{{'courses.cud.buttons.fire_driver' | translate}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="canBeChanged()" class="row">
          <div class="col-md-4">
            <button *ngIf="isCreateMode()" [disabled]="!courseForm.valid" type="submit" class="btn btn-success">{{'courses.cud.buttons.create' | translate}}</button>

            <button *ngIf="isDetailsMode()" type="button" class="btn btn-primary" (click)="doEdit()">{{'courses.cud.buttons.edit' | translate}}</button>

            <button *ngIf="isEditMode()" type="submit" class="btn btn-success">{{'courses.cud.buttons.save' | translate}}</button>
            <button *ngIf="isEditMode()" type="button" class="btn btn-primary" (click)="cancelOnEdit()">{{'courses.cud.buttons.cancel' | translate}}</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>