import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocalStorageService } from 'src/app/domain/services/local-storage.service';

@Injectable()
export class AuthorizedGuard implements CanActivate {

  constructor(private localStorageService: LocalStorageService, private router: Router) {}
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.localStorageService.loadAuthenticatedUser()) {
      return true;
    } else {
      this.router.navigate(['/auth/login'], {
        queryParams: {
          return: state.url
        }
      });
      return false;
    }
  }
}