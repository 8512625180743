import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ApiResponse, CourseDto, ApiResponsePaginated, Course } from './model';

@Injectable()
export class CoursesService {

    constructor(
        private http: HttpClient
    ) {
        this.apiBaseUrl = environment.apiUrl;
    }

    private apiBaseUrl: string;

    getCoursesFromApi(): Observable<ApiResponsePaginated<CourseDto>> {
        return this.http.get<ApiResponsePaginated<CourseDto>>(this.apiBaseUrl + 'api/courses/browse');
    }

    getCoursesFromApiSmart(pageSize: number): Observable<CourseDto[]> {
        return this.http.get<CourseDto[]>(this.apiBaseUrl + 'api/courses/smart?pageSize=' + pageSize);
    }

    registerForCourse(courseId: string): Observable<ApiResponse<CourseDto>> {
        return this.http.post<ApiResponse<CourseDto>>(this.apiBaseUrl + 'api/courses/' + courseId + '/register', null);
    }

    unregisterFromCourse(courseId: string): Observable<ApiResponse<CourseDto>> {
        return this.http.post<ApiResponse<CourseDto>>(this.apiBaseUrl + 'api/courses/' + courseId + '/unregister', null);
    }

    deleteCourse(courseId: string): Observable<ApiResponse<string>> {
        return this.http.delete<ApiResponse<string>>(this.apiBaseUrl + 'api/courses/' + courseId);
    }

    getMockCourse(): Course {
        let c1 = new Course();
        c1.departureCountry = 'Polska';
        c1.departureCity = 'Warszawa';
        c1.arrivalCountry = 'Polska';
        c1.arrivalCity = 'Kraków';
        c1.startDate = '2018-11-25';
        c1.approximateEndDate = '2018-11-30';
        c1.priceCurrency = 'EUR';
        c1.priceValue = '1000';
        c1.cargoType = 'DC_COOLER';
        c1.driverDocuments = ['DD_CAT_B', 'DD_CAT_C_AND_E'];
        return c1;
    }

    getCourse(id: string): Observable<ApiResponse<CourseDto>> {
        return this.http.get<ApiResponse<CourseDto>>(this.apiBaseUrl + 'api/courses/' + id);
    }

    addCourse(course: Course): Observable<ApiResponse<string>> {
        return this.http.post<ApiResponse<string>>(this.apiBaseUrl + 'api/courses', course);
    }

    editCourse(id: string, course: Course): Observable<ApiResponse<string>> {
        return this.http.post<ApiResponse<string>>(this.apiBaseUrl + 'api/courses/' + id, course);
    }

}