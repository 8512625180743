import { Component, OnInit } from '@angular/core';
import { AuthenticatedUserDto, UpdateUserProfileDto, UserProfile } from '../../services/model';
import { LocalStorageService } from '../../services/local-storage.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UsersService } from '../../services/user.service';
import { LogsService } from '../../services/logs.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastsService } from '../../services/toasts.service';

@Component({
  selector: 'app-users-profile',
  templateUrl: './users-profile.component.html',
  styleUrls: ['./users-profile.component.scss']
})
export class UsersProfileComponent implements OnInit {

  public form: FormGroup;
  private user: AuthenticatedUserDto;
  private isSubmitted: boolean;
  
  constructor(
    private localStorage: LocalStorageService,
    private fb: FormBuilder,
    private usersService: UsersService,
    private translate: TranslateService,
    private toastsService: ToastsService
  ) { }

  ngOnInit() {
    this.createForm();
    this.fetchUserProfile();
    this.isSubmitted = false;
  }

  // private fetchUserProfile(): void {
  //   this.user = this.localStorage.loadAuthenticatedUser();
  // }

  private fetchUserProfile(): void {
    this.usersService.getProfile().subscribe(result => {
      this.setLocalProfile(result.data);
    }, error => {
      this.toastsService.error(this.translate.instant('user.profile.fetching_error'));
    });
  }

  private setLocalProfile(profile: UserProfile): void {
    this.form.get('name').setValue(profile.userName);
    this.form.get('email').setValue(profile.userEmail);
  }

  private createForm(): void {
    this.form = this.fb.group({
      email: [{ value: '', disabled: true }, Validators.required],
      name: ['', Validators.required]
    });
  }

  public onSubmit(): void {
    if (!this.isSubmitted) {
      if (this.form.valid) {

        let newName = this.form.get('name').value;
        let dto = new UpdateUserProfileDto(newName);

        this.usersService.updateProfile(dto).subscribe(result => {
          LogsService.Log(result);
          if (result.code === 200) {

            this.setLocalProfile(result.data);
            this.localStorage.storeAuthenticatedUserName(result.data.userName);
            // this.user.name = newName;
            // this.localStorage.storeAuthenticatedUser(this.user);

            this.showNotificationWithTranslation('users.profile.text_profile_updated', '', false);
            // this._globalLanguageEvents.setUserName(newName);
          } else {
            LogsService.Error(result);
            this.showNotificationWithTranslation('users.profile.text_profile_update_error', '', true);
          }
        }, error => {
          LogsService.Error(error);
          // let response = JSON.parse(error._body);
          let errorType = 'undefined';
          if(error.error) {
            errorType = error.error.ErrorType;
          }
          this.showNotificationWithTranslation('users.profile.text_profile_update_error', 'error_types.' + errorType, true);
        });
      }
    }
  }

  showNotificationWithTranslation(header: string, body: string, isError: boolean) {
    let tHeader = '';
    let tBody = '';
    if (header.length) tHeader = this.translate.instant(header);
    if (body.length) tBody = this.translate.instant(body);

    this.showNotification(tHeader, tBody, isError);
  }

  showNotification(header: string, body: string, isError: boolean) {
    // this.smModalShow(header, body);
    if(isError) {
      this.toastsService.error(body, header);
    } else {
      this.toastsService.success(body, header);
    }
    LogsService.Log(header);
  }

  // smModalShow(header: string, body: string): void {
  //   alert(header + '\r\n' + body);
  //   //const activeModal = this.modalService.open(DefaultModal, { size: 'sm' });
  //   //activeModal.componentInstance.modalHeader = header;
  //   //activeModal.componentInstance.modalContent = body;
  // }

  isMissing(controlName: string): boolean {
    return this.form.controls[controlName].errors && this.form.controls[controlName].errors.required == true;
  }
}
