<section class="login-block">
  <!-- Container-fluid starts -->
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <!-- Authentication card start -->
        <div *ngIf="isInitiateMode()">
          <form [formGroup]="form" class="md-float-material form-material" action="javascript:">
            <!-- <div class="text-center">
              <img src="assets/images/logo.png" alt="logo.png">
            </div> -->
            <div class="auth-box card">
              <div class="card-block">
                <div class="row m-b-20">
                  <div class="col-md-12">
                    <h3 class="text-left">{{'auth.forgot.form_title' | translate}}</h3>
                  </div>
                </div>

                <div class="form-group form-primary">
                  <input formControlName="email" type="text" name="email-address" class="form-control" required="">
                  <div class="messages text-danger" *ngIf="form.controls.email.touched && (form.controls.email.errors?.required || form.controls.email.errors?.minlength)">{{'auth.forgot.form_validation.email_required' | translate}}</div>
                  <div class="messages text-danger" *ngIf="form.controls.email.touched && (!form.controls.email.errors?.required && form.controls.email.errors?.validateEmail)">{{'auth.forgot.form_validation.email_invalid' | translate}}</div>
                  <span class="form-bar"></span>
                  <label class="float-label">{{'auth.forgot.form_username' | translate}}</label>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <button [disabled]="!form.valid" type="submit" class="btn btn-primary btn-md btn-block text-center m-b-20 ripple light" (click)="reset()">{{'auth.forgot.button_reset' | translate}}</button>
                  </div>
                </div>
                <p class="f-w-600 text-right">
                  <a [routerLink]="['/auth/login']">{{'auth.forgot.link_back_to_login' | translate}}</a>
                </p>
                <!-- <div class="row">
                  <div class="col-md-10">
                    <p class="text-inverse text-left m-b-0">Thank you.</p>
                    <p class="text-inverse text-left"><a [routerLink]="['/dashboard/default']"><b>Back to website</b></a></p>
                  </div>
                </div> -->
              </div>
            </div>
          </form>
        </div>
        <div *ngIf="isConfirmMode()">
          <form [formGroup]="formConfirmation" class="md-float-material form-material" action="javascript:">
            <!-- <div class="text-center">
              <img src="assets/images/logo.png" alt="logo.png">
            </div> -->
            <div class="auth-box card">
              <div class="card-block">
                <div class="row m-b-20">
                  <div class="col-md-12">
                    <h3 class="text-left">{{'auth.forgot.form_confirm_title' | translate}}</h3>
                  </div>
                </div>

                <div class="row" formGroupName="passwords">
                  <div class="col-sm-6">
                    <div class="form-group form-primary">
                      <input formControlName="password" type="password" name="password" class="form-control" required="">
                      <div class="messages text-danger" *ngIf="formConfirmation.get('passwords').get('password').touched && formConfirmation.get('passwords').get('password').errors?.required">{{'auth.forgot.form_validation.password_required' | translate}}</div>
                      <div class="messages text-danger" *ngIf="formConfirmation.get('passwords').get('password').touched && (!formConfirmation.get('passwords').get('password').errors?.required && formConfirmation.get('passwords').get('password').errors?.minlength)">{{'auth.forgot.form_validation.password_invalid' | translate}}</div>
                      <span class="form-bar"></span>
                      <label class="float-label">{{'auth.forgot.form_password' | translate}}</label>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group form-primary">
                      <input formControlName="repeatPassword" type="password" name="confirm-password" class="form-control" required="">
                      <div class="messages text-danger" *ngIf="formConfirmation.get('passwords').get('repeatPassword').touched && formConfirmation.get('passwords').get('repeatPassword').errors?.required">{{'auth.forgot.form_validation.repeatpassword_required' | translate}}</div>
                      <div class="messages text-danger" *ngIf="formConfirmation.get('passwords').get('repeatPassword').touched && (!formConfirmation.get('passwords').get('repeatPassword').errors?.required && formConfirmation.get('passwords').errors?.passwordsEqual)">{{'auth.forgot.form_validation.passwords_notequal' | translate}}</div>
                      <span class="form-bar"></span>
                      <label class="float-label">{{'auth.forgot.form_password_confirm' | translate}}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <button [disabled]="!formConfirmation.valid" type="submit" class="btn btn-primary btn-md btn-block text-center m-b-20 ripple light"
                      (click)="onSubmitConfirmation()">{{'auth.forgot.button_reset_confirm' | translate}}</button>
                  </div>
                </div>
                <p class="f-w-600 text-right">
                  <a [routerLink]="['/auth/login']">{{'auth.forgot.link_back_to_login' | translate}}</a>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- end of col-sm-12 -->
    </div>
    <!-- end of row -->
  </div>
  <!-- end of container-fluid -->
</section>