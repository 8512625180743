import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'src/app/domain/services/local-storage.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(private localStorageService: LocalStorageService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let user = this.localStorageService.loadAuthenticatedUser();

        if (user) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${user.authToken}`
                }
            });
        }
        return next.handle(request);
    }
}