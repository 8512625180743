<div class="page-body">
  <div class="row">
    <div class="col-sm-12">
      <!-- List view card start -->
      <div class="card">
        <div class="card-header">
          <h5>{{'courses.browse.text_list_of_courses' | translate}}</h5>

        </div>
        <div class="row card-block">
          <div class="col-md-12">
            <label *ngIf="!anyCourses()">{{'courses.browse.text_no_courses' | translate}}</label>
            <div class="card-loader" *ngIf="loadCard"><i class="fa fa-spinner rotate-refresh"></i></div>
            <ul class="list-view">
              <li *ngFor="let c of allCourses">
                <div class="card list-view-media" [ngClass]="courseClass(c)">
                  <div class="card-block">
                    <div class="media">
                      <div class="row">
                        <div class="col-sm-12">
                          <span class="d-inline-block">
                            <label class="label label-inverse-info">{{c.startDate}}</label>
                            <label class="label label-inverse-warning">{{c.price}}</label>
                            <label class="label label-inverse-info">{{c.statusText}}</label>
                            <!-- <label class="label label-info">{{c.cargoType}}</label>
                            <label class="label label-warning">{{c.truckType}}</label>
                            <label class="label label-primary">{{c.driverDocuments.join(' | ')}}</label> -->
                            {{c.departureCity}} (<b>{{c.departureCountry}}</b>) > {{c.arrivalCity}} (<b>{{c.arrivalCountry}}</b>) [{{c.distance}}]
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer" *ngIf="shouldShowFooter(c)">
                    <div class="media">
                      <div class="row">
                        <div class="col-sm-12">
                          <a *ngIf="canViewDetails(c)" [routerLink]="['/courses-edit', c.courseId]">
                            <label class="btn btn-info btn-mini">
                              {{'courses.browse.text_action_details' | translate}}
                            </label>
                          </a>
                          <a *ngIf="canDelete(c)" (click)="delete(c.courseId)">
                              <label class="btn btn-danger btn-mini">
                                {{'courses.browse.text_action_delete' | translate}}
                              </label>
                            </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- List view card end -->
    </div>
  </div>
</div>