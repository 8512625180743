import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { LocalStorageService } from './local-storage.service';
import { RegisterNewUserDto, ApiResponse, ResetPasswordDto, UpdateUserProfileDto, UserProfileDto, LoginUserDto, AuthenticatedUserDto, LoginUserWithTokenDto, UserAvailabilityDto, UserProfile } from './model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class UsersService {

    private apiBaseUrl: string;

    constructor(
        private http: HttpClient,
        private localStorage: LocalStorageService,
        private router: Router
    ) {
        this.apiBaseUrl = environment.apiUrl;
    }

    // private GetHttpOptions(): RequestOptionsArgs {
    //     let headers = new Headers();
    //     let authenticatedUser = this.localStorage.loadAuthenticatedUser();
    //     if (authenticatedUser) {
    //       headers.append('Authorization', 'Bearer ' + authenticatedUser.authToken);
    //     }
    //     return new RequestOptions({ headers: headers });
    //   }

    //   pingSecure(): Observable<ApiResponseString[]> {
    //     return this.http.get(this.baseUrl + 'api/users/pingSecure', this.GetHttpOptions())
    //       .map(response => response.json())
    //       ;
    //   }

    redirectToLoginPage() {
        this.router.navigateByUrl('/login');
    }

    //   registerNewUser(dto: RegisterNewUserDto): Observable<ApiResponseBool[]> {
    //     return this.http.post(this.baseUrl + 'api/users/register', dto)
    //       .map(response => response.json())
    //       ;
    //   }

    registerNewUser(dto: RegisterNewUserDto): Observable<ApiResponse<boolean>> {
        return this.http.post<ApiResponse<boolean>>(this.apiBaseUrl + 'api/users/register', dto);
    }

    // resetPassword(dto: ResetPasswordDto): Observable<ApiResponseBool[]> {
    //     return this.http.post(this.baseUrl + 'api/users/reset', dto)
    //       .map(response => response.json())
    //       ;
    //   }

    resetPassword(dto: ResetPasswordDto): Observable<ApiResponse<boolean>> {
        return this.http.post<ApiResponse<boolean>>(this.apiBaseUrl + 'api/users/reset', dto);
    }

    //   resetPasswordConfirm(email: string, token: string, password: string): Observable<ApiResponseBool[]> {
    //     return this.http.get(this.baseUrl + 'api/users/reset?email=' + email + '&token=' + token + '&newPassword=' + password)
    //       .map(response => response.json())
    //       ;
    //   }

    resetPasswordConfirm(email: string, token: string, password: string): Observable<ApiResponse<boolean>> {
        return this.http.get<ApiResponse<boolean>>(this.apiBaseUrl + 'api/users/reset?email=' + email + '&token=' + token + '&newPassword=' + password);
    }

    //   updateProfile(profile: UpdateUserProfileDto): Observable<ApiResponse<UserProfileDto[]>> {
    //     return this.http.put(this.baseUrl + 'api/users/profile', profile, this.GetHttpOptions())
    //       .map(response => response.json())
    //   }

    updateProfile(profile: UpdateUserProfileDto): Observable<ApiResponse<UserProfileDto>> {
        return this.http.post<ApiResponse<UserProfileDto>>(this.apiBaseUrl + 'api/users/profile', profile);
    }

    //   activateUser(email: string, token: string): Observable<ApiResponseBool[]> {
    //     return this.http.get(this.baseUrl + 'api/users/activate?email=' + email + '&token=' + token)
    //       .map(response => response.json())
    //   }

    activateUser(email: string, token: string): Observable<ApiResponse<boolean>> {
        return this.http.get<ApiResponse<boolean>>(this.apiBaseUrl + 'api/users/activate?email=' + email + '&token=' + token);
    }

    //   loginUser(dto: LoginUserDto): Observable<ApiResponseAuthenticatedUser[]> {
    //     return this.http.post(this.baseUrl + 'api/users/login', dto)
    //       .map(response => response.json())
    //       ;
    //   }

    loginUser(dto: LoginUserDto): Observable<ApiResponse<AuthenticatedUserDto>> {
        return this.http.post<ApiResponse<AuthenticatedUserDto>>(this.apiBaseUrl + 'api/users/login', dto);
    }

    // fakeLogin() {
    //     this.loginUser(new LoginUserDto('email-d083632a-df60-4191-9367-508053ab0445@address', 'P@ssw0rd')).subscribe(result => {
    //         console.log(result);
    //         var user = result.data;
    //         this.localStorage.storeAuthenticatedUser(user);
    //     });
    // }

    //   loginFacebookUser(authToken: string): Observable<ApiResponseAuthenticatedUser[]> {
    //     let dto = new LoginUserWithTokenDto(authToken);
    //     return this.http.post(this.baseUrl + 'api/users/fblogin', dto)
    //       .map(response => response.json())
    //       ;
    //   }

    loginFacebookUser(token: string): Observable<ApiResponse<AuthenticatedUserDto>> {
        let dto = new LoginUserWithTokenDto(token);
        return this.http.post<ApiResponse<AuthenticatedUserDto>>(this.apiBaseUrl + 'api/users/fblogin', dto);
    }

    //   loadDriversAvailability(): Observable<ApiResponse<UserAvailabilityDto[]>[]> {
    //     return this.http.get(this.baseUrl + 'api/users/availability', this.GetHttpOptions())
    //       .map(response => response.json())
    //   }

    loadDriversAvailability(): Observable<ApiResponse<UserAvailabilityDto[]>> {
        return this.http.get<ApiResponse<UserAvailabilityDto[]>>(this.apiBaseUrl + 'api/users/availability');
    }

    loadAuthenticatedUser(): AuthenticatedUserDto {
        return this.localStorage.loadAuthenticatedUser();
    }

    logoffUser() {
        this.localStorage.clearAuthenticatedUser();
        this.router.navigateByUrl('/login');
    }

    getProfile(): Observable<ApiResponse<UserProfile>> {
        return this.http.get<ApiResponse<UserProfile>>(this.apiBaseUrl + 'api/users/profile');
    }

    sendSubscriptionToServer(subscription: PushSubscription): Observable<ApiResponse<boolean>> {
        return this.http.post<ApiResponse<boolean>>(this.apiBaseUrl + 'api/users/subscriptions', subscription);
    }

}