import { Routes } from '@angular/router';
import { AdminComponent } from './layout/admin/admin.component';
import { CoursesListComponent } from './domain/courses/courses-list/courses-list.component';
import { CoursesEditComponent } from './domain/courses/courses-edit/courses-edit.component';
import { DriversMapComponent } from './domain/drivers/drivers-map/drivers-map.component';
import { UsersProfileComponent } from './domain/users/users-profile/users-profile.component';
import { SocialLoginComponent } from 'src/app/domain/auth/social-login/social-login.component';
import { AnonymousGuard } from 'src/app/domain/services/guards/anonymous.guard';
import { LogoutComponent } from './domain/auth/logout/logout.component';
import { AuthorizedGuard } from './domain/services/guards/authorized.guard';
import { RegistrationComponent } from './domain/auth/registration/registration.component';
import { ForgotComponent } from 'src/app/domain/auth/forgot/forgot.component';
import { ActivateComponent } from './domain/auth/activate/activate.component';
import { PremiumGuard } from './domain/services/guards/premium.guard';
import { SubscriptionComponent } from './domain/premium/subscription/subscription.component';
import { SuccessComponent } from './domain/premium/payment/success/success.component';
import { FailureComponent } from './domain/premium/payment/failure/failure.component';
var ɵ0 = { title: 'menu.courses_list' }, ɵ1 = { title: 'menu.courses_edit' }, ɵ2 = { title: 'menu.courses_new' }, ɵ3 = { title: 'menu.drivers_map' }, ɵ4 = { title: 'menu.users_profile' }, ɵ5 = { title: 'menu.logout' };
var routes = [
    { path: '', redirectTo: '/auth/login', pathMatch: 'full', canActivate: [AnonymousGuard] },
    { path: 'auth/registration', component: RegistrationComponent, canActivate: [AnonymousGuard] },
    { path: 'activate', component: ActivateComponent, canActivate: [AnonymousGuard] },
    { path: 'reset', component: ForgotComponent, canActivate: [AnonymousGuard] },
    { path: 'auth/login', component: SocialLoginComponent, canActivate: [AnonymousGuard] },
    {
        path: '', component: AdminComponent, canActivate: [AuthorizedGuard], children: [
            { path: 'courses-list', component: CoursesListComponent, data: ɵ0 },
            { path: 'courses-edit/:courseId', component: CoursesEditComponent, data: ɵ1 },
            { path: 'courses-new', component: CoursesEditComponent, data: ɵ2, canActivate: [PremiumGuard] },
            { path: 'drivers-map', component: DriversMapComponent, data: ɵ3, canActivate: [PremiumGuard] },
            { path: 'users-profile', component: UsersProfileComponent, data: ɵ4 },
            { path: 'logout', component: LogoutComponent, data: ɵ5 },
            { path: 'subscription', component: SubscriptionComponent },
            { path: 'subscription/payment/success', component: SuccessComponent },
            { path: 'subscription/payment/failure', component: FailureComponent },
        ]
    },
    { path: '**', redirectTo: '/auth/login', pathMatch: 'full' }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
