<div class="page-body">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>{{'drivers.map.title' | translate}}</h5>
        </div>
        <div class="row card-block">
          <div class="col-sm-6">
            <label>{{'drivers.map.availability' | translate}}{{availableDrivers}}</label>
          </div>
          <agm-map [latitude]="lat" [longitude]="lng" [maxZoom]="zoom" [fitBounds]="true">
            <agm-marker-cluster imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m">
              <agm-marker *ngFor="let m of markers" [latitude]="m.latitude" [longitude]="m.longitude" [agmFitBounds]="true">
                <agm-info-window [disableAutoPan]="false" #infoWindow>
                  <div>
                    <font color="black">
                      <h3>{{m.name}}</h3>
                      <p>{{m.address}}</p>
                      <h5>{{m.start}} : {{m.stop}}</h5>
                      <p>{{m.phone}}</p>
                      <p>{{getDriverDocuments(m)}}</p>
                    </font>
                  </div>
                </agm-info-window>
              </agm-marker>
            </agm-marker-cluster>
          </agm-map>
        </div>
      </div>
    </div>
  </div>
</div>