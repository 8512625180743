import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UsersService } from './user.service';
import { AuthenticatedUserDto } from './model';

@Injectable()
export class FeatureService {

    private isPremiumImplemented: boolean;

    constructor(private _userService: UsersService) {
        this.isPremiumImplemented = environment.isPremiumImplemented;
    }

    public isEnabled(): boolean {
        if(this.isPremiumImplemented == false) return true;

        let user = this._userService.loadAuthenticatedUser();
        if(user == null) return false;

        return this.isPremiumUser(user);
    }

    private isPremiumUser(user: AuthenticatedUserDto) : boolean {
        return user.isPremium == "1";
    }
}