import {Injectable} from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  short_label?: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMS = [
  {
    label: 'Lista kursów',
    main: [
      {
        state: 'courses-list',
        short_label: 'S',
        name: 'menu.courses_list',
        type: 'link',
        icon: 'feather icon-list'
      }
    ]
  },
  {
    label: 'Dodaj kurs',
    main: [
      {
        state: 'courses-new',
        short_label: 'CS',
        name: 'menu.courses_new',
        type: 'link',
        icon: 'feather icon-plus-circle',
        target: true
      }
    ]
  },
  {
    label: 'Kierowcy',
    main: [
      {
        state: 'drivers-map',
        short_label: 'S',
        name: 'menu.drivers_map',
        type: 'link',
        icon: 'feather icon-map-pin'
      }
    ]
  },
  {
    label: 'Profil',
    main: [
      {
        state: 'users-profile',
        short_label: 'S',
        name: 'menu.users_profile',
        type: 'link',
        icon: 'feather icon-user'
      }
    ]
  },
  // {
  //   label: 'Wyloguj',
  //   main: [
  //     {
  //       state: 'logout',
  //       short_label: 'S',
  //       name: 'menu.logout',
  //       type: 'link',
  //       icon: 'feather icon-power'
  //     }
  //   ]
  // }
];


@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }
}
