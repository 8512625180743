import { Component, OnInit, ElementRef, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { CoursesService } from '../../services/courses.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CourseComponentMode, Course, DICT_CARGO_TYPES, DICT_DRIVER_DOCUMENTS, DICT_TRUCK_TYPES, Option } from '../../services/model';
import { LogsService } from '../../services/logs.service';
import { Secrets } from '../../../../environments/secrets';
import { ToastsService } from '../../services/toasts.service';


@Component({
  selector: 'app-courses-edit',
  templateUrl: './courses-edit.component.html',
  styleUrls: ['./courses-edit.component.scss']
})
export class CoursesEditComponent implements OnInit {

  public showDirections: boolean = false;
  public origin: any;
  public destination: any;
  public mapLatitude: any;
  public mapLongitude: any;

  private setFakeData = false;
  private currentCourseId: string;
  private mode: CourseComponentMode;
  public course = new Course();

  public dictCargoTypes = DICT_CARGO_TYPES;
  public dictDriverDocuments = DICT_DRIVER_DOCUMENTS.sort((a, b) => (a.name > b.name) ? 1 : -1);
  public dictTruckTypes = DICT_TRUCK_TYPES;

  // public mapRouteDistance: ReplaySubject<string>;
  public mapRouteDistance: string = '';
  // public mapRouteDuration: ReplaySubject<string>;
  public mapRouteDuration: string = '';

  private _map; // map to add Markers, Routes
  private _mapDeparture;
  private _mapArrival;
  private _mapDirectionsService;
  private _mapDirectionsDisplay;
  private _mapBrowserCurrentPosition;

  public driverName: string;
  public driverPhone: string;

  public courseForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private coursesService: CoursesService,
    private router: Router,
    private translate: TranslateService,
    private elementRef: ElementRef,
    private ngZone: NgZone,
    private modalService: NgbModal,
    private toastsService: ToastsService,
  ) {
    // this.mapRouteDistance = new ReplaySubject<string>(1);
    // this.mapRouteDuration = new ReplaySubject<string>(1);
  }

  ngOnInit() {
    this.hackRouter();
    let id = this.route.snapshot.paramMap.get('courseId');
    if (id) {
      this.mode = CourseComponentMode.Details;
      this.currentCourseId = id;
    } else {
      this.mode = CourseComponentMode.New;
    }
    this.initGoogleMaps();
    this.loadInitialData();
    this.createForm();

    // register form on changes
    this.courseForm.get('departure').valueChanges.pipe(debounceTime(1000)).subscribe(val => {
      this.setRouteDeparture(val.country, val.city);
    });
    this.courseForm.get('arrival').valueChanges.pipe(debounceTime(1000)).subscribe(val => {
      this.setRouteArrival(val.country, val.city);
    });
  }

  private setRouteDeparture(country: string, city: string) {
    this.showDirections = true;
    this.origin = country + ' ' + city;
    // this._mapDeparture = country + ' ' + city;
    // if (this._mapArrival) {
    //   this.showRouteOnMap(this._mapDeparture, this._mapArrival);
    // }
  }

  private setRouteArrival(country: string, city: string) {
    this.showDirections = true;
    this.destination = country + ' ' + city;
    // this._mapArrival = country + ' ' + city;
    // if (this._mapDeparture) {
    //   this.showRouteOnMap(this._mapDeparture, this._mapArrival);
    // }
  }

  private showRouteOnMap(departure: string, arrival: string) {
    // let directionsService = this._mapDirectionsService;
    // let directionsDisplay = this._mapDirectionsDisplay;
    // let mapRouteDistance = this.mapRouteDistance;
    // let mapRouteDuration = this.mapRouteDuration;
    // let ngZone = this.ngZone;
    // GoogleMapsLoader.load((google) => {

    //   var request = {
    //     origin: departure,
    //     destination: arrival,
    //     travelMode: google.maps.TravelMode.DRIVING
    //   };

    //   directionsService.route(request, function (response, status) {
    //     LogsService.Log(response);
    //     LogsService.Log(status);
    //     if (status == google.maps.DirectionsStatus.OK) {
    //       directionsDisplay.setDirections(response);

    //       ngZone.run(() => {
    //         mapRouteDistance.next(response.routes[0].legs[0].distance.text);
    //         mapRouteDuration.next(response.routes[0].legs[0].duration.text);
    //       });

    //     } else {
    //       //alert('error' + status);
    //     }
    //   });
    // });
  }

  private initGoogleMaps() {
    // jest jakis darmowy kredyt 300$
    // na dzien 2019-01-28 23:30 zostalo jeszcze 1,126.83 zeta
    // GoogleMapsLoader.KEY = Secrets.GOOGLE_MAPS_API_KEY;

    let el = this.elementRef.nativeElement.querySelector('.google-maps');

    // TODO: zapisac do localStorage dla usera jego pozycje i jak juz jest w oninit to to pokazujemy
    // TODO: i zarejestrowac sie do zmiany getCurrentPosition i odswiezyc strone i localstorage
    // TODO: a jak nie ma wogole pozycji, to warszawa
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(position => {
        LogsService.Log('position');
        LogsService.Log(position);
        this.mapLatitude = position.coords.latitude;
        this.mapLongitude = position.coords.longitude;
        // this._mapBrowserCurrentPosition = {
        //   latitude: position.coords.latitude,
        //   longitude: position.coords.longitude
        // };
        // GoogleMapsLoader.load((google) => {
        //   this._map.setCenter(new google.maps.LatLng(position.coords.latitude, position.coords.longitude));
        // });
      });
    }

    // TODO: do not load this each time as we already have the library after first attempt
    // GoogleMapsLoader.load((google) => {

    //   let warsaw = new google.maps.LatLng(52.22977, 21.01178);
    //   let map = new google.maps.Map(el, {
    //     center: warsaw,
    //     zoom: 8,
    //     mapTypeId: google.maps.MapTypeId.ROADMAP
    //   });

    //   this._map = map;

    //   var directionsService = new google.maps.DirectionsService();
    //   var directionsDisplay = new google.maps.DirectionsRenderer();
    //   directionsDisplay.setMap(map);

    //   this._mapDirectionsService = directionsService;
    //   this._mapDirectionsDisplay = directionsDisplay;
    // });
  }

  private hackRouter() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });
  }

  private loadInitialData() {
    if (this.mode == CourseComponentMode.New) {
      this.course.status = Course.STATUS_NEW;
      if (this.setFakeData) {
        this.course = this.coursesService.getMockCourse();
      }
    } else {
      this.coursesService.getCourse(this.currentCourseId).subscribe(result => {
        LogsService.Log(result);
        let tmp = [];
        tmp.push(result);

        this.course = tmp[0].data;

        // todo: tu mozna chyba caly obiekt i patch?
        this.courseForm.get('departure').get('country').setValue(tmp[0].data.departureCountry);
        this.courseForm.get('departure').get('city').setValue(tmp[0].data.departureCity);
        this.courseForm.get('arrival').get('country').setValue(tmp[0].data.arrivalCountry);
        this.courseForm.get('arrival').get('city').setValue(tmp[0].data.arrivalCity);
        this.courseForm.get('price').get('value').setValue(tmp[0].data.priceValue);
        this.courseForm.get('price').get('currency').setValue(tmp[0].data.priceCurrency);
        this.courseForm.get('startDate').setValue(tmp[0].data.startDate.substring(0, 10));
        this.courseForm.get('approximateEndDate').setValue(tmp[0].data.approximateEndDate.substring(0, 10));
        this.courseForm.get('cargoType').setValue(Course.DictCargoTypeId(tmp[0].data.cargoType));
        this.courseForm.get('truckType').setValue(Course.DictTruckTypeId(tmp[0].data.truckType));
        this.courseForm.get('driverDocuments').setValue(this.dictDriverDocuments.map(x => this.isOptionChecked(x)));
        this.courseForm.get('phoneNumber').setValue(tmp[0].data.phoneNumber);
        LogsService.Log(this.courseForm);

        this.driverName = tmp[0].data.driverName;
        this.driverPhone = tmp[0].data.driverPhone;

        this.setRouteDeparture(
          tmp[0].data.departureCountry,
          tmp[0].data.departureCity,
        );
        this.setRouteArrival(
          tmp[0].data.arrivalCountry,
          tmp[0].data.arrivalCity,
        );
        LogsService.Log('set both route points');

      }, error => {
        LogsService.Log('this is error');
        LogsService.Log(error);
        LogsService.Error(error);
        if (error.status == 404) {
          this.router.navigateByUrl('/pages/courses/list');
        }
      });
    }
  }

  private createForm() {

    LogsService.Log(this.course);

    this.courseForm = this.fb.group({
      price: this.fb.group({
        value: [this.course.priceValue, Validators.required],
        currency: [this.course.priceCurrency, Validators.required],
      }),
      departure: this.fb.group({
        country: [this.course.departureCountry, Validators.required],
        city: [this.course.departureCity, Validators.required],
      }),
      arrival: this.fb.group({
        country: [this.course.arrivalCountry, Validators.required],
        city: [this.course.arrivalCity, Validators.required],
      }),
      startDate: [this.course.startDate, Validators.required],
      approximateEndDate: [this.course.approximateEndDate, Validators.required],
      cargoType: [this.course.cargoType, Validators.required],
      truckType: [this.course.truckType, Validators.required],
      driverDocuments: this.fb.array(this.dictDriverDocuments.map(x => [this.isOptionChecked(x)]), Validators.required),
      phoneNumber: [this.course.phoneNumber, Validators.required]
    });

    LogsService.Log(this.courseForm);

    if (this.mode == CourseComponentMode.Details) {
      this.courseForm.disable();
    }
  }

  onSubmit() {
    // TODO: Use EventEmitter with form value
    LogsService.Log('submiting');
    LogsService.Log(this.courseForm.controls.phoneNumber.touched);
    LogsService.Log(this.courseForm.controls.phoneNumber);
    LogsService.Log(this.courseForm.valid);
    LogsService.Log(this.courseForm.value);
    if (this.courseForm.valid) {
      if (this.mode == CourseComponentMode.New) {
        this.postNewCourseFormToBackend(this.courseForm);
      } else {
        this.putEditedCourseFormToBackend(this.courseForm);
      }
    }
  }

  postNewCourseFormToBackend(form: FormGroup) {

    let dto = this.convertFormToDto(form);

    this.coursesService.addCourse(dto).subscribe(result => {
      LogsService.Log(result);
      let tmp = [];
      tmp.push(result);
      if (tmp[0].code === 200) {
        this.showNotificationWithTranslation('courses.cud.messages.course_added', '', false);
        this.router.navigate(['/courses-edit/' + tmp[0].data]);
      } else {
        LogsService.Error(tmp[0]);
        this.showNotificationWithTranslation('courses.cud.messages.adding_error', '', true);
      }
    }, error => {
      LogsService.Error(error); 
      LogsService.Error(error.error);
      let response = error.error;
      this.showNotificationWithTranslation('courses.cud.messages.adding_error', 'error_types.' + response.ErrorType, true);
    });

  }

  putEditedCourseFormToBackend(form: FormGroup) {

    let dto = this.convertFormToDto(form);

    this.coursesService.editCourse(this.currentCourseId, dto).subscribe(result => {
      let tmp = [];
      tmp.push(result);
      if (tmp[0].code === 200) {
        this.showNotificationWithTranslation('courses.cud.messages.course_edited', '', false);
        this.router.navigate(['/courses-edit/' + tmp[0].data]);
      } else {
        LogsService.Error(tmp[0]);
        this.showNotificationWithTranslation('courses.cud.messages.edit_error', '', true);
      }
    }, error => {
      LogsService.Error(error);
      let response = error.error;
      this.showNotificationWithTranslation('courses.cud.messages.edit_error', 'error_types.' + response.ErrorType, true);
    });

  }

  convertFormToDto(form: FormGroup): Course {
    let dto = new Course();
    dto.departureCountry = form.get('departure').get('country').value;
    dto.departureCity = form.get('departure').get('city').value;
    dto.arrivalCountry = form.get('arrival').get('country').value;
    dto.arrivalCity = form.get('arrival').get('city').value;
    dto.startDate = form.get('startDate').value;
    dto.approximateEndDate = form.get('approximateEndDate').value;
    dto.priceValue = form.get('price').get('value').value;
    dto.priceCurrency = form.get('price').get('currency').value;
    dto.cargoType = Course.DictCargoTypeName(form.get('cargoType').value);
    dto.truckType = Course.DictTruckTypeName(form.get('truckType').value);
    dto.driverDocuments = form.get('driverDocuments').value
      .map((v, i) => v ? this.dictDriverDocuments[i].id : null)
      .filter(v => v !== null)
      .map(x => Course.DictDriverDocumentName(x))
      ;
    dto.phoneNumber = form.get('phoneNumber').value;

    let calculatedDistance = (<HTMLInputElement>document.getElementById("calculatedDistance")).value;
    let calculatedDuration = (<HTMLInputElement>document.getElementById("calculatedDuration")).value;
    dto.calculatedDistance = calculatedDistance;
    dto.calculatedDuration = calculatedDuration;

    LogsService.Log(form.get('driverDocuments').value);
    LogsService.Log(dto.driverDocuments);
    return dto;
  }

  doEdit() {
    this.mode = CourseComponentMode.Edit;
    this.courseForm.enable();
  }

  isEditMode(): boolean {
    return this.mode == CourseComponentMode.Edit;
  }

  isDetailsMode(): boolean {
    return this.mode == CourseComponentMode.Details;
  }

  isCreateMode(): boolean {
    return this.mode == CourseComponentMode.New;
  }

  cancelOnEdit() {
    this.mode = CourseComponentMode.Details;
    this.courseForm.disable();
  }

  // showNotification(header: string, body: string) {
  //   // this.smModalShow(header, body);
  //   LogsService.Log(header);
  // }

  showNotificationWithTranslation(header: string, body: string, isError: boolean) {
    let tHeader = '';
    let tBody = '';
    if (header.length) tHeader = this.translate.instant(header);
    if (body.length) tBody = this.translate.instant(body);

    // this.showNotification(tHeader, tBody);
    if(isError) {
      this.toastsService.error(tBody, tHeader);
    } else {
      this.toastsService.success(tBody, tHeader);
    }
  }

  // smModalShow(header: string, body: string): void {
  //   // alert('fix this');
  //   // const activeModal = this.modalService.open(DefaultModal, { size: 'sm' });
  //   // activeModal.componentInstance.modalHeader = header;
  //   // activeModal.componentInstance.modalContent = body;
  // }

  isOptionChecked(option: Option): boolean {
    LogsService.Log('is option checked');
    LogsService.Log(option);
    LogsService.Log(this.course);
    LogsService.Log(this.course.driverDocuments);
    return this.course.driverDocuments ? this.course.driverDocuments.indexOf(option.name) > -1 : false;
  }

  //mapRouteDistanceValue() {
  //  return this.mapRouteDistance;
  //}

  //mapRouteDurationValue() {
  //  return this.mapRouteDuration;
  //}

  getClassForRequiredFormField(fieldName: string): string {
    let control = this.courseForm.get(fieldName);
    if (this.isControlInvalid(control)) return 'has-error';
    if (this.isControlValid(control)) return 'has-success';
    return '';
  }

  getClassForRequiredFormGroupField(fieldGroup: string, fieldName: string): string {
    let control = this.courseForm.get(fieldGroup).get(fieldName);
    if (this.isControlInvalid(control)) return 'has-error';
    if (this.isControlValid(control)) return 'has-success';
    return '';
  }

  private isControlValid(control: AbstractControl): boolean {
    return control.touched && control.valid;
  }

  private isControlInvalid(control: AbstractControl): boolean {
    return control.touched && !control.valid;
  }

  isRequiredFieldMissing(fieldName: string): boolean {
    return this.courseForm.get(fieldName).touched &&
      this.courseForm.get(fieldName).errors &&
      this.courseForm.get(fieldName).errors.required;
  }

  isRequiredGroupFieldMissing(fieldGroup: string, fieldName: string): boolean {
    return this.courseForm.get(fieldGroup).get(fieldName).touched &&
      this.courseForm.get(fieldGroup).get(fieldName).errors &&
      this.courseForm.get(fieldGroup).get(fieldName).errors.required;
  }

  canBeChanged(): boolean {
    return this.course.status == Course.STATUS_NEW ||
      this.course.status == Course.STATUS_EXPIRED ||
      this.course.status == Course.STATUS_FINISHED;
  }

  shouldDisplayDriverDetails(): boolean {
    return this.course.status == Course.STATUS_REGISTERED ||
      this.course.status == Course.STATUS_ONGOING ||
      this.course.status == Course.STATUS_EXECUTED;
  }

  fireDriver(): void {
    this.coursesService.unregisterFromCourse(this.currentCourseId).subscribe(result => {
      let tmp = [];
      tmp.push(result);
      if (tmp[0].code === 200) {
        this.showNotificationWithTranslation('courses.cud.messages.driver_fired', '', false);
        this.router.navigate(['/pages/courses/cud/' + this.currentCourseId]);
      } else {
        LogsService.Error(tmp[0]);
        this.showNotificationWithTranslation('courses.cud.messages.driver_firing_error', '', true);
      }
    }, error => {
      LogsService.Error(error);
      let response = error.error;
      LogsService.Log(response);
      this.showNotificationWithTranslation('courses.cud.messages.driver_firing_error', 'error_types.' + response.ErrorType, true);
    });
  }

  public mapsResponse(event: any){
    console.warn(event);
    if(event.routes.length > 0 && event.routes[0].legs.length > 0) {
      this.mapRouteDistance = event.routes[0].legs[0].distance.text;
      this.mapRouteDuration = event.routes[0].legs[0].duration.text;
    } else {
      this.showDirections = false;
      this.mapRouteDistance = '';
      this.mapRouteDuration = '';
    }
  }

}
