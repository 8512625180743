import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

// Sentry.init({
//     dsn: "https://297a3bed7a254064b8d16bc0a6be5747@sentry.io/1450475",
//     environment: environment.name
// });

@Injectable()
export class LogsService {

    private ShouldSendToSentry(): boolean {
        return environment.name != 'DEV';
    }

    private static ShouldSendToSentry(): boolean {
        return environment.name != 'DEV';
    }

    public Log(message?: any, ...optionalParams: any[]): void {
        console.log(message, optionalParams);
        if (this.ShouldSendToSentry()) {
            // Sentry.captureMessage(message, Sentry.Severity.Info);
        }
    }

    public Error(message?: any, ...optionalParams: any[]): void {
        console.error(message, optionalParams);
        if (this.ShouldSendToSentry()) {
            // Sentry.captureException(message.originalError || message);
        }
    }

    public Warn(message?: any, ...optionalParams: any[]): void {
        console.warn(message, optionalParams);
        if (this.ShouldSendToSentry()) {
            // Sentry.captureMessage(message, Sentry.Severity.Warning);
        }
    }

    public Info(message?: any, ...optionalParams: any[]): void {
        console.info(message, optionalParams);
        if (this.ShouldSendToSentry()) {
            // Sentry.captureMessage(message, Sentry.Severity.Info);
        }
    }

    public static Log(message?: any, ...optionalParams: any[]): void {
        console.log(message, optionalParams);
        if (this.ShouldSendToSentry()) {
            // Sentry.captureMessage(message, Sentry.Severity.Info);
        }
    }

    public static Error(message?: any, ...optionalParams: any[]): void {
        console.error(message, optionalParams);
        if (this.ShouldSendToSentry()) {
            // Sentry.captureException(message.originalError || message);
        }
    }
}