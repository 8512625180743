/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./shared/spinner/spinner.component.ngfactory";
import * as i4 from "./shared/spinner/spinner.component";
import * as i5 from "@angular/common";
import * as i6 from "../../node_modules/ng2-toasty/src/toasty.component.ngfactory";
import * as i7 from "ng2-toasty/src/toasty.component";
import * as i8 from "ng2-toasty/src/toasty.service";
import * as i9 from "./app.component";
import * as i10 from "@ngx-translate/core";
import * as i11 from "./app.update.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 3, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-spinner", [], null, null, null, i3.View_SpinnerComponent_0, i3.RenderType_SpinnerComponent)), i1.ɵdid(3, 180224, null, 0, i4.SpinnerComponent, [i2.Router, i5.DOCUMENT], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "ng2-toasty", [], null, null, null, i6.View_ToastyComponent_0, i6.RenderType_ToastyComponent)), i1.ɵdid(5, 114688, null, 0, i7.ToastyComponent, [i8.ToastyConfig, i8.ToastyService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 5, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, null, null, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i9.AppComponent, [i2.Router, i10.TranslateService, i11.AppUpdateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i9.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
