/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./breadcrumbs.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./breadcrumbs.component";
import * as i5 from "@angular/router";
var styles_BreadcrumbsComponent = [i0.styles];
var RenderType_BreadcrumbsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BreadcrumbsComponent, data: {} });
export { RenderType_BreadcrumbsComponent as RenderType_BreadcrumbsComponent };
function View_BreadcrumbsComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h4", [["class", "m-b-10"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_v.parent.context.$implicit.label)); _ck(_v, 1, 0, currVal_0); }); }
function View_BreadcrumbsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BreadcrumbsComponent_5)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.last; _ck(_v, 2, 0, currVal_0); }, null); }
function View_BreadcrumbsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "page-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "row align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "col-md-8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "page-header-title"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BreadcrumbsComponent_4)), i1.ɵdid(5, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.breadcrumbs; _ck(_v, 5, 0, currVal_0); }, null); }
function View_BreadcrumbsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "page-header"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BreadcrumbsComponent_3)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.last; _ck(_v, 2, 0, currVal_0); }, null); }
function View_BreadcrumbsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", ""]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BreadcrumbsComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.status && _v.context.last); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_BreadcrumbsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BreadcrumbsComponent_1)), i1.ɵdid(1, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.breadcrumbs; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_BreadcrumbsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-breadcrumbs", [], null, null, null, View_BreadcrumbsComponent_0, RenderType_BreadcrumbsComponent)), i1.ɵdid(1, 49152, null, 0, i4.BreadcrumbsComponent, [i5.Router, i5.ActivatedRoute], null, null)], null, null); }
var BreadcrumbsComponentNgFactory = i1.ɵccf("app-breadcrumbs", i4.BreadcrumbsComponent, View_BreadcrumbsComponent_Host_0, {}, {}, []);
export { BreadcrumbsComponentNgFactory as BreadcrumbsComponentNgFactory };
