import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { MenuItems } from './shared/menu-items/menu-items';
import { BreadcrumbsComponent } from './layout/admin/breadcrumbs/breadcrumbs.component';
import { CoursesListComponent } from './domain/courses/courses-list/courses-list.component';
import { CoursesEditComponent } from './domain/courses/courses-edit/courses-edit.component';
import { DriversMapComponent } from './domain/drivers/drivers-map/drivers-map.component';
import { UsersProfileComponent } from './domain/users/users-profile/users-profile.component';
import { UsersLogoutComponent } from './domain/users/users-logout/users-logout.component';
import { TranslateModule, TranslateService, TranslateStore, TranslateLoader } from '@ngx-translate/core';

import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction'; 
import { Secrets } from 'src/environments/secrets';
import { UsersService } from './domain/services/user.service';
import { LocalStorageService } from './domain/services/local-storage.service';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TokenInterceptor } from 'src/app/domain/services/guards/token.interceptor';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { CoursesService } from './domain/services/courses.service';
import { LogsService } from './domain/services/logs.service';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { SocialLoginComponent } from './domain/auth/social-login/social-login.component';
import { AnonymousGuard } from './domain/services/guards/anonymous.guard';
import { ToastsService } from './domain/services/toasts.service';
import { ToastyService, ToastyConfig, ToastyModule } from 'ng2-toasty';
import { LogoutComponent } from './domain/auth/logout/logout.component';
import { AuthorizedGuard } from './domain/services/guards/authorized.guard';
import { RegistrationComponent } from './domain/auth/registration/registration.component';
import { ForgotComponent } from './domain/auth/forgot/forgot.component';
import { ActivateComponent } from './domain/auth/activate/activate.component';
import { UnauthorizedInterceptor } from 'src/app/domain/services/guards/unauthorized.interceptor';
import { ApplicationErrorHandler } from 'src/app/domain/services/application-error.handler';
import { PushSubscriptionService } from './domain/services/push.subscription.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppUpdateService } from './app.update.service';
import { PremiumGuard } from './domain/services/guards/premium.guard';
import { SubscriptionComponent } from './domain/premium/subscription/subscription.component';
import { FeatureService } from './domain/services/feature.service';
import { TPayPaymentLinkGenerator } from './domain/services/tpay.payment.link.generator';
import { PremiumSubscriptionService } from './domain/services/premium.subscription.service';
import { SuccessComponent } from './domain/premium/payment/success/success.component';
import { FailureComponent } from './domain/premium/payment/failure/failure.component';


@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    BreadcrumbsComponent,
    CoursesListComponent,
    CoursesEditComponent,
    DriversMapComponent,
    UsersProfileComponent,
    UsersLogoutComponent,
    SocialLoginComponent,
    LogoutComponent,
    RegistrationComponent,
    ForgotComponent,
    ActivateComponent,
    SubscriptionComponent,
    SuccessComponent,
    FailureComponent
  ],
  imports: [
    TranslateModule.forRoot(
      {
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      }
    ),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    AgmCoreModule.forRoot({ apiKey: Secrets.GOOGLE_MAPS_API_KEY }),
    AgmJsMarkerClustererModule,
    AgmDirectionModule,
    ReactiveFormsModule,
    ToastyModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true })
  ],
  schemas: [],
  providers: [
    {provide: ErrorHandler, useClass: ApplicationErrorHandler},
    AppUpdateService,
    MenuItems,
    UsersService,
    LocalStorageService, 
    TranslateService, 
    TranslateStore,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true
    },
    CoursesService,
    LogsService,
    FormBuilder,
    AnonymousGuard,
    AuthorizedGuard,
    FeatureService,
    PremiumGuard,
    ToastsService,
    PushSubscriptionService,
    PremiumSubscriptionService,
    TPayPaymentLinkGenerator
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
