import { OnInit, ElementRef } from '@angular/core';
import { UsersService } from '../../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { UserAvailabilityDto } from '../../services/model';
import { LogsService } from '../../services/logs.service';
var DriversMapComponent = /** @class */ (function () {
    function DriversMapComponent(elementRef, usersService, translateService) {
        this.elementRef = elementRef;
        this.usersService = usersService;
        this.translateService = translateService;
        this.availableDrivers = 0;
        this.lat = null;
        this.lng = null;
        this.zoom = 9;
        this.markers = [];
        this.numberOfAvailableDrivers = 0;
    }
    DriversMapComponent.prototype.ngOnInit = function () {
        var _this = this;
        // this.usersService.fakeLogin();
        // this.initGoogleMaps();
        this.usersService.loadDriversAvailability().subscribe(function (result) {
            // console.warn(result);
            _this.availableDrivers = result.data.length;
            result.data.forEach(function (x) { return _this.addDriverToMap(x); });
        }, function (error) {
            LogsService.Error(error);
        });
    };
    DriversMapComponent.prototype.addDriverToMap = function (driver) {
        this.markers.push(driver);
    };
    // private initGoogleMaps() {
    //   // jest jakis darmowy kredyt 300$
    //   // na dzien 2019-01-28 23:30 zostalo jeszcze 1,126.83 zeta
    //   GoogleMapsLoader.KEY = Secrets.GOOGLE_MAPS_API_KEY;
    //   let el = this.elementRef.nativeElement.querySelector('.google-maps');
    //   //if (window.navigator.geolocation) {
    //   //  window.navigator.geolocation.getCurrentPosition(position => {
    //   //    LogsService.Log('position');
    //   //    LogsService.Log(position);
    //   //    this._mapBrowserCurrentPosition = {
    //   //      latitude: position.coords.latitude,
    //   //      longitude: position.coords.longitude
    //   //    };
    //   //    GoogleMapsLoader.load((google) => {
    //   //      this._map.setCenter(new google.maps.LatLng(position.coords.latitude, position.coords.longitude));
    //   //    });
    //   //  });
    //   //}
    //   // TODO: do not load this each time as we already have the library after first attempt
    //   GoogleMapsLoader.load((google) => {
    //     let warsaw = new google.maps.LatLng(52.22977, 21.01178);
    //     let map = new google.maps.Map(el, {
    //       center: warsaw,
    //       zoom: 8,
    //       mapTypeId: google.maps.MapTypeId.ROADMAP
    //     });
    //     this._map = map;
    //     this.fetchDrivers(google);
    //     //var directionsService = new google.maps.DirectionsService();
    //     //var directionsDisplay = new google.maps.DirectionsRenderer();
    //     //directionsDisplay.setMap(map);
    //     //this._mapDirectionsService = directionsService;
    //     //this._mapDirectionsDisplay = directionsDisplay;
    //   });
    // }
    DriversMapComponent.prototype.fetchDrivers = function (google) {
        var _this = this;
        this.usersService.loadDriversAvailability().subscribe(function (result) {
            var tmp = [];
            tmp.push(result);
            var allLocations = tmp[0].data;
            _this.numberOfAvailableDrivers = allLocations.length;
            if (allLocations.length == 0) {
                var warsaw = new google.maps.LatLng(52.22977, 21.01178);
                _this._map.setCenter(warsaw);
            }
            else {
                var bounds = new google.maps.LatLngBounds();
                var markers = [];
                var _loop_1 = function () {
                    var driverDocuments = _this.getDriverDocuments(allLocations[i]);
                    var location_1 = new google.maps.LatLng(+allLocations[i].latitude, +allLocations[i].longitude);
                    var marker = new google.maps.Marker({
                        draggable: false,
                        //animation: google.maps.Animation.DROP,
                        position: location_1,
                        //map: this._map,//set map created here
                        title: allLocations[i].name + "\r\n" + allLocations[i].address + "\r\n" + allLocations[i].start + " : " + allLocations[i].stop + '\r\n' + allLocations[i].phone + '\r\n' + driverDocuments
                    });
                    markers.push(marker);
                    marker.addListener('click', function () {
                        var title = this.getTitle();
                        var elements = title.split('\r\n');
                        var phone = elements[3];
                        if (phone == 'null') {
                            phone = '';
                        }
                        var content = '<div><font color="black"><h3>' + elements[0] + '</h3><p>' + elements[1] + '</p><h5>' + elements[2] + '</h5><p>' + phone + '</p><p>' + driverDocuments + '</p></font></div>';
                        var infowindow = new google.maps.InfoWindow({
                            content: content
                        });
                        infowindow.open(this._map, marker);
                    });
                    bounds.extend(location_1);
                };
                for (var i = 0; i < allLocations.length; i++) {
                    _loop_1();
                }
                // var markerCluster = new MarkerClusterer(this._map, markers,
                //   { imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' });
                _this._map.fitBounds(bounds);
            }
            if (_this._map.getZoom() > 10) {
                _this._map.setZoom(10);
            }
        }, function (error) {
            console.error(error);
        });
    };
    DriversMapComponent.prototype.getDriverDocuments = function (availability) {
        var _this = this;
        var result = '';
        if (availability && availability.driverDocuments && availability.driverDocuments.length > 0) {
            result = this.unique(availability.driverDocuments).map(function (x) { return _this.translateService.instant('dict.' + x); }).join(', ');
        }
        return result;
    };
    DriversMapComponent.prototype.unique = function (values) {
        var result = [];
        for (var i = 0; i < values.length; i++) {
            if (result.indexOf(values[i]) == -1) {
                result.push(values[i]);
            }
        }
        return result;
    };
    return DriversMapComponent;
}());
export { DriversMapComponent };
// interface agmmarker {
//   lat?: number;
//   lng?: number;
// }
