import { Injectable, ApplicationRef } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { first } from 'rxjs/operators';
import { interval, concat } from 'rxjs';
import { LogsService } from './domain/services/logs.service';

@Injectable()
export class AppUpdateService {
    constructor(
        appRef: ApplicationRef,
        swUpdate: SwUpdate,
        private logs: LogsService
    ) {
        
        

        // Allow the app to stabilize first, before starting polling for updates with `interval()`.
        const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
        const everySixHours$ = interval(10 * 1000);
        const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

        everySixHoursOnceAppIsStable$.subscribe(() => {
            
            swUpdate.activated.subscribe(e => {
                this.logs.Info("+++ SW UPDATE : activated");
                this.logs.Info(e);
            });
    
            swUpdate.available.subscribe(e => {
                this.logs.Info("+++ SW UPDATE : available");
                this.logs.Info(e);
                // if (promptUser(event)) {
                    swUpdate.activateUpdate().then(() => document.location.reload());
                // }
            })
            
            // this.logs.Warn('+++ check for update...');
            swUpdate.checkForUpdate();
        });
    }
}