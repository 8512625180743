import { Injectable } from '@angular/core';
import { LogsService } from './logs.service';
import { UsersService } from './user.service';
import { SwPush } from '@angular/service-worker';
import { Router } from '@angular/router';

@Injectable()
export class PushSubscriptionService {

    constructor(
        private swPush: SwPush,
        private logs: LogsService,
        private usersService: UsersService,
        private router: Router
    ) {

    }

    public AskUserForPushSubscriptionIfNeeded() {
        console.warn('pytam');
        console.warn(this.swPush.isEnabled);
        if (this.swPush.isEnabled) {
            this.swPush.requestSubscription({
                serverPublicKey: 'BMjtLnoiQaupgYIJTz4MDyuOa4uSblDGFZfr4hZLrS5FOqUik1OL7mXbxOzAKso1Mw-8JCLhd-W8qfUECnKN1Kg'
            })
                .then(subscription => {
                    this.logs.Info('User allows subscriptions');
                    this.usersService.sendSubscriptionToServer(subscription).subscribe();
                })
                .catch(error => {
                    this.logs.Error('Subscription error:' + error);
                });

            this.swPush.notificationClicks.subscribe(nc => {
                this.logs.Info('User clicked notification');
                this.router.navigateByUrl('/courses-list');
            })
        }
    }
}