import { Injectable, Inject } from '@angular/core';

import { Observable } from 'rxjs/';
import 'rxjs/add/observable/of'
import { of } from 'rxjs/observable/of';
import { map } from 'rxjs/operator/map';
import { AuthenticatedUserDto } from './model';

@Injectable()
export class LocalStorageService {

  private TheItemKeyUserName = 'auth-user-name';
  private TheItemKeyUserEmail = 'auth-user-email';
  private TheItemKeyUserAuthToken = 'auth-user-auth-token';
  private TheItemKeyUserPremium = 'auth-user-is-premium';

  clearAuthenticatedUser() {
    localStorage.removeItem(this.TheItemKeyUserName);
    localStorage.removeItem(this.TheItemKeyUserEmail);
    localStorage.removeItem(this.TheItemKeyUserAuthToken);
    localStorage.removeItem(this.TheItemKeyUserPremium);
  }

  storeAuthenticatedUser(user: AuthenticatedUserDto) {
    localStorage.setItem(this.TheItemKeyUserName, user.name);
    localStorage.setItem(this.TheItemKeyUserEmail, user.email);
    localStorage.setItem(this.TheItemKeyUserAuthToken, user.authToken);
    localStorage.setItem(this.TheItemKeyUserPremium, this.getIsPremiumFromToken(user.authToken));
  }

  private getIsPremiumFromToken(authToken: string): string {
    var token = atob(authToken.split('.')[1]);
    var json = JSON.parse(token);
    return json.ispremium;
  }

  getUserIdFromToken(): string {
    var user = this.loadAuthenticatedUser();
    if (user) {
      var token = atob(user.authToken.split('.')[1]);
      var json = JSON.parse(token);
      return json.nameid;
    }
  }

  storeAuthenticatedUserName(name: string) {
    localStorage.setItem(this.TheItemKeyUserName, name);
  }

  loadAuthenticatedUser(): AuthenticatedUserDto {
    let userAuthToken = localStorage.getItem(this.TheItemKeyUserAuthToken);
    if (userAuthToken == null) return null;

    let userName = localStorage.getItem(this.TheItemKeyUserName);
    let userEmail = localStorage.getItem(this.TheItemKeyUserEmail);
    let userPremium = localStorage.getItem(this.TheItemKeyUserPremium);
    let user = new AuthenticatedUserDto();
    user.name = userName;
    user.email = userEmail;
    user.authToken = userAuthToken;
    user.isPremium = userPremium;
    return user;
  }

  enableUserPremium() {
    localStorage.setItem(this.TheItemKeyUserPremium, "1");
  }
}
