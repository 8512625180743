import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LocalStorageService } from '../local-storage.service';
import { FeatureService } from '../feature.service';

@Injectable()
export class PremiumGuard implements CanActivate {

  constructor(private feature: FeatureService, private router: Router) {}
  
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.feature.isEnabled()) {
      return true;
    } else {
      this.router.navigate(['/subscription']);
      return false;
    }
  }
}