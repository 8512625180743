<section class="login-block">
    <!-- Container-fluid starts -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <!-- Authentication card start -->
          <form [formGroup]="form" class="md-float-material form-material">
            <!-- <div class="text-center">
                <img src="assets/images/logo.png" alt="logo.png">
              </div> -->
            <div class="auth-box card">
              <div class="card-block">
                <!-- <div class="row m-b-20">
                  <div class="col-md-12">
                    <h3 class="text-center txt-primary">Sign In</h3>
                  </div>
                </div> -->
                <div class="row m-b-20">
                  <div class="col-md-6 . m-auto">
                    <button type="button" class="btn btn-facebook m-b-20 btn-block ripple light" (click)="launchFbLogin()">
                      <i class="fa fa-facebook"></i>{{'auth.login.text_facebook' | translate}}</button>
                  </div>
                  <!-- <div class="col-md-6">
                    <button type="button" class="btn btn-twitter m-b-20 btn-block ripple light"><i class="fa fa-twitter"></i>twitter</button>
                  </div> -->
                </div>
                <!-- <p class="text-muted text-center p-b-5">Sign in with your regular account</p> -->
                <div class="form-group form-primary">
                  <input formControlName="email" type="text" name="user-name" class="form-control" required="">
                  <div class="messages text-danger" *ngIf="form.controls.email.touched && (form.controls.email.errors?.required || form.controls.email.errors?.minlength)">{{'auth.login.form_validation.email_required' | translate}}</div>
                  <div class="messages text-danger" *ngIf="form.controls.email.touched && (!form.controls.email.errors?.required && form.controls.email.errors?.validateEmail)">{{'auth.login.form_validation.email_invalid' | translate}}</div>
                  <span class="form-bar"></span>
                  <label class="float-label">{{'auth.login.form_username' | translate}}</label>
                </div>
                <div class="form-group form-primary">
                  <input formControlName="password" type="password" name="password" class="form-control" required="">
                  <div class="messages text-danger" *ngIf="form.controls.password.touched && form.controls.password.errors?.required">{{'auth.login.form_validation.password_required' | translate}}</div>
                  <span class="form-bar"></span>
                  <label class="float-label">{{'auth.login.form_password' | translate}}</label>
                </div>
                <div class="row m-t-25 text-left">
                  <div class="col-12">
                    <!-- <div class="checkbox-fade fade-in-primary">
                      <label>
                        <input type="checkbox" value="">
                        <span class="cr"><i class="cr-icon fa fa-check txt-primary"></i></span>
                        <span class="text-inverse">Remember me</span>
                      </label>
                    </div> -->
                    <div class="forgot-phone text-right float-right">
                      <a [routerLink]="['/reset']" class="text-right f-w-600">{{'auth.login.link_forgot_password' | translate}}</a>
                    </div>
                  </div>
                </div>
                <div class="row m-t-30">
                  <div class="col-md-12">
                    <button [disabled]="!form.valid" type="submit" class="btn btn-primary btn-md btn-block text-center m-b-20 ripple light" (click)="login()">{{'auth.login.button_login' | translate}}</button>
                  </div>
                </div>
                <p class="text-inverse text-left">{{'auth.login.link_register_question' | translate}}
                  <a [routerLink]="['/auth/registration']">
                    <b>{{'auth.login.link_register_action' | translate}}</b>
                  </a>
                </p>
              </div>
            </div>
          </form>
          <!-- end of form -->
        </div>
        <!-- Authentication card end -->
      </div>
      <!-- end of col-sm-12 -->
    </div>
    <!-- end of container-fluid -->
  </section>