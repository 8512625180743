import { Component, OnInit, ElementRef } from '@angular/core';
import { UsersService } from '../../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { UserAvailabilityDto, LoginUserDto } from '../../services/model';
import { LogsService } from '../../services/logs.service';

declare var google: any;

@Component({
  selector: 'app-drivers-map',
  templateUrl: './drivers-map.component.html',
  styleUrls: ['./drivers-map.component.scss']
})
export class DriversMapComponent implements OnInit {

  availableDrivers: number = 0;

  lat = null;
  lng = null;
  zoom = 9;

  public markers : UserAvailabilityDto[] = [];

  private _map; // map to add Markers, Routes

  public numberOfAvailableDrivers: number = 0;

  constructor(
    private elementRef: ElementRef,
    private usersService: UsersService,
    private translateService: TranslateService,
  ) {

  }

  ngOnInit(): void {
    // this.usersService.fakeLogin();
    // this.initGoogleMaps();
    this.usersService.loadDriversAvailability().subscribe(result => {
      // console.warn(result);
      this.availableDrivers = result.data.length;
      result.data.forEach(x => this.addDriverToMap(x));
    }, error => {
      LogsService.Error(error);
    });
  }

  private addDriverToMap(driver: UserAvailabilityDto) {
    this.markers.push(driver);
  }

  // private initGoogleMaps() {
  //   // jest jakis darmowy kredyt 300$
  //   // na dzien 2019-01-28 23:30 zostalo jeszcze 1,126.83 zeta
  //   GoogleMapsLoader.KEY = Secrets.GOOGLE_MAPS_API_KEY;

  //   let el = this.elementRef.nativeElement.querySelector('.google-maps');

  //   //if (window.navigator.geolocation) {
  //   //  window.navigator.geolocation.getCurrentPosition(position => {
  //   //    LogsService.Log('position');
  //   //    LogsService.Log(position);
  //   //    this._mapBrowserCurrentPosition = {
  //   //      latitude: position.coords.latitude,
  //   //      longitude: position.coords.longitude
  //   //    };
  //   //    GoogleMapsLoader.load((google) => {
  //   //      this._map.setCenter(new google.maps.LatLng(position.coords.latitude, position.coords.longitude));
  //   //    });
  //   //  });
  //   //}

  //   // TODO: do not load this each time as we already have the library after first attempt
  //   GoogleMapsLoader.load((google) => {

  //     let warsaw = new google.maps.LatLng(52.22977, 21.01178);
  //     let map = new google.maps.Map(el, {
  //       center: warsaw,
  //       zoom: 8,
  //       mapTypeId: google.maps.MapTypeId.ROADMAP
  //     });

  //     this._map = map;

  //     this.fetchDrivers(google);

  //     //var directionsService = new google.maps.DirectionsService();
  //     //var directionsDisplay = new google.maps.DirectionsRenderer();
  //     //directionsDisplay.setMap(map);

  //     //this._mapDirectionsService = directionsService;
  //     //this._mapDirectionsDisplay = directionsDisplay;
  //   });
  // }

  private fetchDrivers(google: any): void {

    this.usersService.loadDriversAvailability().subscribe(result => {
      let tmp = [];
      tmp.push(result);
      let allLocations = tmp[0].data;
      this.numberOfAvailableDrivers = allLocations.length;
      if (allLocations.length == 0) {
        let warsaw = new google.maps.LatLng(52.22977, 21.01178);
        this._map.setCenter(warsaw);
      } else {
        let bounds = new google.maps.LatLngBounds();
        let markers = [];
        for (var i = 0; i < allLocations.length; i++) {
          let driverDocuments = this.getDriverDocuments(allLocations[i]);
          let location = new google.maps.LatLng(+allLocations[i].latitude, +allLocations[i].longitude);
          let marker = new google.maps.Marker({
            draggable: false,
            //animation: google.maps.Animation.DROP,
            position: location,
            //map: this._map,//set map created here
            title: allLocations[i].name + "\r\n" + allLocations[i].address + "\r\n" + allLocations[i].start + " : " + allLocations[i].stop + '\r\n' + allLocations[i].phone + '\r\n' + driverDocuments
          });
          markers.push(marker);

          marker.addListener('click', function () {
            var title = this.getTitle();
            var elements = title.split('\r\n');
            var phone = elements[3];
            if (phone == 'null') {
              phone = '';
            }

            var content = '<div><font color="black"><h3>' + elements[0] + '</h3><p>' + elements[1] + '</p><h5>' + elements[2] + '</h5><p>' + phone + '</p><p>' + driverDocuments + '</p></font></div>';
            var infowindow = new google.maps.InfoWindow({
              content: content
            });
            infowindow.open(this._map, marker);
          });

          bounds.extend(location);
        }
        // var markerCluster = new MarkerClusterer(this._map, markers,
        //   { imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m' });
        this._map.fitBounds(bounds);
      }
      if (this._map.getZoom() > 10) {
        this._map.setZoom(10);
      }
    }, error => {
      console.error(error);
    });
  }

  private getDriverDocuments(availability: UserAvailabilityDto): string {
    let result = '';
    if (availability && availability.driverDocuments && availability.driverDocuments.length > 0) {
      result = this.unique(availability.driverDocuments).map(x => this.translateService.instant('dict.' + x)).join(', ');
    }
    return result;
  }

  private unique(values: string[]): string[] {
    let result = [];
    for (var i = 0; i < values.length; i++) {
      if (result.indexOf(values[i]) == -1) {
        result.push(values[i]);
      }
    }
    return result;
  }
}

// interface agmmarker {
//   lat?: number;
//   lng?: number;
// }