import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppUpdateService } from './app.update.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = '';

  constructor(
    private router: Router,
    private translateService: TranslateService,
    appUpdateService: AppUpdateService
  ) { 
    this.translateService.setDefaultLang('en');
    this.translateService.use('pl');
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
