import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../services/local-storage.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private _router: Router,
    private _localStorageService: LocalStorageService,
  ) {
    this.logout();
   }

  ngOnInit() {
  }

  logout() {
    this._localStorageService.clearAuthenticatedUser();
    this._router.navigateByUrl('/');
  }

}
