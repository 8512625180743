import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UsersService } from '../../services/user.service';
import { LogsService } from '../../services/logs.service';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss']
})
export class ActivateComponent implements OnInit {

  private activationEmail: string;
  private activationToken: string;

  public activationInProgress: boolean = true;
  public activationOk: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private usersService: UsersService,
    private logs: LogsService
  ) { 
    this.activationEmail = route.snapshot.queryParamMap.get('email');
    this.activationToken = route.snapshot.queryParamMap.get('token');
  }

  ngOnInit() {
    document.querySelector('body').setAttribute('themebg-pattern', 'theme1');

    this.usersService.activateUser(this.activationEmail, this.activationToken).subscribe(result => {
      this.activationInProgress = false;
      this.activationOk = true;
    }, error => {
      this.activationInProgress = false;
      this.activationOk = false;
    });
  }

  public showInProgress(): boolean {
    return this.activationInProgress == true;
  }

  public showSuccess(): boolean {
    return this.activationInProgress == false && this.activationOk == true;
  }

  public showError(): boolean {
    return this.activationInProgress == false && this.activationOk == false;
  }


}
