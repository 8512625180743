<div class="page-body">
  <div class="row">
    <div class="col-sm-12">
      <form class="form-material" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="card">
          <div class="card-header">
            <h5>{{'users.profile.text_profile' | translate}}</h5>
          </div>
          <div class="row card-block">
            <div class="col-sm-12">
              <div class="form-group form-primary form-static-label">
                <input type="text" name="footer-email" class="form-control" required="" placeholder="{{'users.profile.placeholder_email' | translate}}"
                  formControlName="email">
                <span class="form-bar"></span>
                <label class="float-label">{{'users.profile.label_email' | translate}}</label>
              </div>
              <div class="form-group form-static-label" [ngClass]="isMissing('name') ? 'form-danger' : 'form-primary'">
                <input type="text" name="footer-name" class="form-control" required="a" placeholder="{{'users.profile.placeholder_name' | translate}}"
                  formControlName="name">
                <span class="form-bar"></span>
                <label class="float-label">{{'users.profile.label_name' | translate}}</label>
                <div class="messages text-danger" *ngIf="isMissing('name')">{{'users.profile.form_validation_field_required' | translate}}</div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="media">
              <div class="row">
                <div class="col-sm-12">
                  <input [disabled]="!this.form.valid" type="submit" class="btn btn-info" value="{{'users.profile.text_action_save' | translate}}">
                  &nbsp;
                  <a class="btn btn-success" [routerLink]="['/subscription']">{{'users.profile.link_to_subscription_text' | translate}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>