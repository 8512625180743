import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import { CoursesListComponent } from './domain/courses/courses-list/courses-list.component';
import { CoursesEditComponent } from './domain/courses/courses-edit/courses-edit.component';
import { DriversMapComponent } from './domain/drivers/drivers-map/drivers-map.component';
import { UsersProfileComponent } from './domain/users/users-profile/users-profile.component';
import { UsersLogoutComponent } from './domain/users/users-logout/users-logout.component';
import { SocialLoginComponent } from 'src/app/domain/auth/social-login/social-login.component';
import { AnonymousGuard } from 'src/app/domain/services/guards/anonymous.guard';
import { LogoutComponent } from './domain/auth/logout/logout.component';
import { AuthorizedGuard } from './domain/services/guards/authorized.guard';
import { RegistrationComponent } from './domain/auth/registration/registration.component';
import { ForgotComponent } from 'src/app/domain/auth/forgot/forgot.component';
import { ActivateComponent } from './domain/auth/activate/activate.component';
import { PremiumGuard } from './domain/services/guards/premium.guard';
import { SubscriptionComponent } from './domain/premium/subscription/subscription.component';
import { SuccessComponent } from './domain/premium/payment/success/success.component';
import { FailureComponent } from './domain/premium/payment/failure/failure.component';

const routes: Routes = [
  { path: '', redirectTo: '/auth/login', pathMatch: 'full', canActivate: [AnonymousGuard] },
  { path: 'auth/registration', component: RegistrationComponent, canActivate: [AnonymousGuard] },
  { path: 'activate', component: ActivateComponent, canActivate: [AnonymousGuard] },
  { path: 'reset', component: ForgotComponent, canActivate: [AnonymousGuard] },
  { path: 'auth/login', component: SocialLoginComponent, canActivate: [AnonymousGuard] },
  {
    path: '', component: AdminComponent, canActivate: [AuthorizedGuard], children: [
      { path: 'courses-list', component: CoursesListComponent, data: { title: 'menu.courses_list' } },
      { path: 'courses-edit/:courseId', component: CoursesEditComponent, data: { title: 'menu.courses_edit' } },
      { path: 'courses-new', component: CoursesEditComponent, data: { title: 'menu.courses_new' }, canActivate: [PremiumGuard] },
      { path: 'drivers-map', component: DriversMapComponent, data: { title: 'menu.drivers_map' }, canActivate: [PremiumGuard] },
      { path: 'users-profile', component: UsersProfileComponent, data: { title: 'menu.users_profile' } },
      { path: 'logout', component: LogoutComponent, data: { title: 'menu.logout' } },
      { path: 'subscription', component: SubscriptionComponent },
      { path: 'subscription/payment/success', component: SuccessComponent },
      { path: 'subscription/payment/failure', component: FailureComponent },
    ]
  },
  { path: '**', redirectTo: '/auth/login', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
