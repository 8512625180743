//// TODO: zrobic API dla slownikow plus cache w ang
export const DICT_CARGO_TYPES: Option[] = [
  {
    id: 1,
    name: 'DC_COOLER'
  },
  {
    id: 2,
    name: 'DC_CURTAIN'
  },
  {
    id: 3,
    name: 'DC_TARP'
  },
  {
    id: 4,
    name: 'DC_TIP'
  },
  {
    id: 5,
    name: 'DC_IZOTHERM'
  }
];

export const DICT_DRIVER_DOCUMENTS: Option[] = [
  {
    id: 1,
    name: 'DD_CAT_B'
  },
  {
    id: 2,
    name: 'DD_CAT_C'
  },
  {
    id: 3,
    name: 'DD_CAT_C_AND_E'
  },
  {
    id: 4,
    name: 'DD_CAT_D'
  },
  {
    id: 5,
    name: 'DD_VRUS'
  },
  {
    id: 6,
    name: 'DD_VKAZ'
  },
  {
    id: 7,
    name: 'DD_VBY'
  },
  {
    id: 8,
    name: 'DD_ADR'
  },
  {
    id: 9,
    name: 'DD_CAT_C_AND_E_95'
  }
];

export const DICT_TRUCK_TYPES: Option[] = [
  {
    id: 1,
    name: 'DT_BUS'
  },
  {
    id: 2,
    name: 'DT_SOLO'
  },
  {
    id: 3,
    name: 'DT_TRUCK'
  },
  {
    id: 4,
    name: 'DT_COACH'
  }
];

export const DICT_COURSE_STATUSES: Option[] = [
  {
    id: 1,
    name: 'DS_NEW'
  },
  {
    id: 2,
    name: 'DS_REGISTERED'
  },
  {
    id: 3,
    name: 'DS_ONGOING'
  },
  {
    id: 4,
    name: 'DS_EXPIRED'
  },
  {
    id: 5,
    name: 'DS_EXECUTED'
  },
  {
    id: 6,
    name: 'DS_FINISHED'
  }
];


export class Option {
  id: number;
  name: string;
}

export class Course {
  id: string;
  departureCountry: string;
  departureCity: string;
  arrivalCountry: string;
  arrivalCity: string;
  startDate: string;
  approximateEndDate: string;
  priceValue: string;
  priceCurrency: string;
  cargoType: string;
  truckType: string;
  driverDocuments: string[];
  phoneNumber: string;
  status: string;
  calculatedDistance: string;
  calculatedDuration: string;

  public static DictCargoTypeId(name: string): number {
    return DICT_CARGO_TYPES.filter(x => x.name == name)[0].id;
  }

  public static DictCargoTypeName(id: number): string {
    return DICT_CARGO_TYPES.filter(x => x.id == id)[0].name;
  }

  public static DictDriverDocumentId(name: string): number {
    return DICT_DRIVER_DOCUMENTS.filter(x => x.name == name)[0].id;
  }

  public static DictDriverDocumentName(id: number): string {
    return DICT_DRIVER_DOCUMENTS.filter(x => x.id == id)[0].name;
  }

  public static DictTruckTypeId(name: string): number {
    return DICT_TRUCK_TYPES.filter(x => x.name == name)[0].id;
  }

  public static DictTruckTypeName(id: number): string {
    return DICT_TRUCK_TYPES.filter(x => x.id == id)[0].name;
  }

  public static DictCourseStatusId(name: string): number {
    return DICT_COURSE_STATUSES.filter(x => x.name == name)[0].id;
  }

  public static DictCourseStatusName(id: number): string {
    return DICT_COURSE_STATUSES.filter(x => x.id == id)[0].name;
  }

  public static STATUS_NEW: string = 'DS_NEW';
  public static STATUS_EXPIRED: string = 'DS_EXPIRED';
  public static STATUS_FINISHED: string = 'DS_FINISHED';
  public static STATUS_REGISTERED: string = 'DS_REGISTERED';
  public static STATUS_ONGOING: string = 'DS_ONGOING';
  public static STATUS_EXECUTED: string = 'DS_EXECUTED';
}

//export class ApiResponseCourseList {
//  message: string;
//  code: number;
//  data: Course[];
//}

//export class ApiResponseCourse {
//  message: string;
//  code: number;
//  data: Course;
//}

//export class ApiResponseCourseId {
//  message: string;
//  code: number;
//  data: string;
//}

export class RegisterNewUserDto {
  constructor(name: string, email: string, password: string) {
    this.name = name;
    this.email = email;
    this.password = password;
  }
  name: string;
  email: string;
  password: string;
}

export class ResetPasswordDto {
  constructor(email: string) {
    this.email = email;
  }
  email: string;
}

export class LoginUserDto {
  constructor(email: string, password: string) {
    this.email = email;
    this.password = password;
  }
  email: string;
  password: string;
}

export class LoginUserWithTokenDto {
  constructor(token: string) {
    this.token = token;
  }
  token: string;
}

export class ApiResponseBool {
  message: string;
  code: number;
  data: boolean;
}

export class ApiResponseAuthenticatedUser {
  message: string;
  code: number;
  data: AuthenticatedUserDto
}

export class AuthenticatedUserDto {
  email: string;
  name: string;
  authToken: string;
  isPremium: string;
}

export class UpdateUserProfileDto {
  constructor(newUserName: string) {
    this.newUserName = newUserName;
  }
  newUserName: string
}

export class UserProfileDto {
  userEmail: string;
  userName: string;
}

export class UserAvailabilityDto {
  name: string;
  address: string;
  latitude: string;
  longitude: string;
  start: string;
  stop: string;
  phone: string;
  driverDocuments: string[];
}

export class ApiResponse<T> {
  message: string;
  code: number;
  data: T;
}

//export class CourseDataBasic {
//  id: string;
//  DepartureCity: string;
//  ArrivalCity: string;
//  StartDate: string;
//  Price: string;
//  PriceCurrency: string;
//}

export enum CourseComponentMode {
  New = 1,
  Details,
  Edit
}

export class ApiResponsePaginated<T> {
  message: string;
  code: number;
  data: T[];
}

export class CourseDto {
  id: string;
  departureCountry: string;
  departureCity: string;
  arrivalCountry: string;
  arrivalCity: string;
  startDate: string;
  calculatedDistance: string;
  priceValue: number;
  priceCurrency: string;
  cargoType: string;
  truckType: string;
  driverDocuments: string[];
  phoneNumber: string;
  status: string;
  isEditable: boolean;
  actions: AvailableActions;
}

export class AvailableActions {
  canEdit: boolean;
  canRegister: boolean;
  canUnregister: boolean;
  canDelete: boolean;
  canViewDetails: boolean;
}

export class BrowseCourseViewModel {
  courseId: string
  departureCountry: string;
  departureCity: string;
  arrivalCountry: string;
  arrivalCity: string;
  startDate: string;
  distance: string;
  price: string;
  cargoType: string;
  truckType: string;
  driverDocuments: string[];
  phoneNumber: string;
  status: CourseStatus;
  statusText: string;
  isEditable: boolean;
  actions: AvailableActionsViewModel;
}

export class AvailableActionsViewModel {
  canEdit: boolean;
  canRegister: boolean;
  canUnregister: boolean;
  canDelete: boolean;
  canViewDetails: boolean;
}

export enum CourseStatus {
  Unknown,
  New,
  Registered,
  Ongoing,
  Expired,
  Executed,
  Finished
}

export class UserProfile {
  userEmail: string;
  userName: string;
}

export class SubscriptionDto {
  id: string;
  price: string;
  startingDate: string;
  endingDate: string;
  status: string;
}

export class SubscriptionViewModel {
  id: string;
  price: string;
  startingDate: string;
  endingDate: string;
  status: string;
}

export class Subscription {
  id: string;
  type: string;
  price: string;
  status: string;
  begin: string;
  end: string;

  public static TYPE_MONTHLY: string = 'DST_MONTHLY';
  public static TYPE_INFINITE: string = 'DST_INFINITE';
  public static STATUS_NEW: string = 'DSS_NEW';
  public static STATUS_ACTIVE: string = 'DSS_ACTIVE';
  public static STATUS_EXPIRED: string = 'DSS_EXPIRED';

}

export class InvoiceData {
  subscriptionId: string;
  nip: string;
  name: string;
  address: string;
  zip: string;
  city: string;
}